/* DISPLAY UTILITY STYLES
   ----------------------------- */

.block {
	display: block!important;
}
.inline-block {
	display: inline-block!important;
}
.inline {
	display: inline!important;
}
.display-flex {
	display: flex;
}
.display-none {
	display: none;
}